import { useMemo, useEffect, useReducer, useCallback } from 'react';

import AuthAPI from 'src/api/auth';
import TenantAPI, { TenantInfo } from 'src/api/tenant';

//

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  ONBOARD = 'ONBOARD',
  INVITATION_SIGNUP = 'INVITATION_SIGNUP',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
    tenant: TenantInfo | null;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    tenant: TenantInfo | null;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
  [Types.ONBOARD]: {
    user: AuthUserType;
  };
  [Types.INVITATION_SIGNUP]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  tenant: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      tenant: action.payload.tenant,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
      tenant: action.payload.tenant,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === Types.ONBOARD) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        // const user = await AuthAPI.userProfile();
        // const tenant = await TenantAPI.getTenantInfo();

        const user = profileEndpoint;
        const tenant = infoEndpoint;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              accessToken,
            },
            // @ts-ignore
            tenant,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            tenant: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          tenant: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      email,
      password,
    };

    // const { token: accessToken, user } = await AuthAPI.login(data);
    const { token: accessToken, user } = loginEndpoint;
    // const tenant = await TenantAPI.getTenantInfo(accessToken);
    const tenant = infoEndpoint;

    setSession(accessToken);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken,
        },
        // @ts-ignore
        tenant,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const { token: accessToken, user } = await AuthAPI.register(data);

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            ...user,
            accessToken,
          },
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ONBOARD
  const onboard = useCallback(async (token: string, password: string) => {
    const data = {
      token,
      password,
    };

    const { token: accessToken, user } = await AuthAPI.onboardUser(data);

    setSession(accessToken);

    dispatch({
      type: Types.ONBOARD,
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // Invitation Signup
  const invitationSignup = useCallback(
    async (fullname: string, email: string, password: string, token: string) => {
      const data = {
        display_name: fullname,
        email,
        password,
        token,
      };

      await AuthAPI.invitationSignup(data);

      dispatch({
        type: Types.INVITATION_SIGNUP,
      });
    },
    []
  );

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      tenant: state.tenant,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      logout,
      onboard,
      invitationSignup,
    }),
    [login, logout, register, onboard, invitationSignup, state.user, state.tenant, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

// TO BE REMOVED AFTER DEMO
const profileEndpoint = {
  id: 'U~o2t85denM',
  email: 'soumilroy@pm.me',
  username: 'soumil',
  display_name: 'Soumil',
  tenant_id: 'id1',
  status: 'active',
  profile: {
    onboarding_status: 'password_set',
    default_procodes: null,
  },
};

const infoEndpoint = {
  id: 'id1',
  name: 'ID1',
  status: 'active',
  metadata: {
    onboarding_status: '',
    default_procodes: null,
    default_competitors: [
      {
        id: 'A-DEC, INC.',
      },
      {
        id: 'ADS OR AJAX DENTAL/GUANGZHOU AJAX MEDICAL EQUIPMENT CO\nLTD',
      },
      {
        id: 'AIR TECHNIQUES, INC.',
      },
      {
        id: 'ASI MEDICAL, INC.',
      },
      {
        id: 'ATR',
      },
      {
        id: 'BELL INTERNATIONAL',
      },
      {
        id: 'BRASSLER',
      },
      {
        id: 'Comperessor tech',
      },
      {
        id: 'CROSSTEX INTERNATIONAL INC.',
      },
      {
        id: 'DCI EQUIPMENT',
      },
      {
        id: 'EIE/ANALYTIC DIVISION OF ORMCO CORP.',
      },
      {
        id: 'ENGLE DENTAL SYSTEMS',
      },
      {
        id: 'FLIGHT DENTAL SYSTEMS, HR DENTAL PRODUCTS, INC.',
      },
      {
        id: 'HENRY SCHEIN',
      },
      {
        id: 'KAVO DENTAL',
      },
      {
        id: 'KAVO DENTAL TECHNOLOGIES LLC',
      },
      {
        id: 'KAVO DNETAL GMBH',
      },
      {
        id: 'MAILLEFER INSTRUMENTS HOLDING SARL',
      },
      {
        id: 'MIDMARK CORPORTATION',
      },
      {
        id: 'MIDWEST DENTAL PRODUCTS',
      },
      {
        id: 'NAKANISHI INC.',
      },
      {
        id: 'NSK AMERICA CORP.',
      },
      {
        id: 'NSK TOCHIGI-KEN',
      },
      {
        id: 'PATTERSON DENTAL SUPPLY, INC.',
      },
      {
        id: 'PELTON & CRANE',
      },
      {
        id: 'SAGE PRODUCTS, INC.',
      },
      {
        id: 'SIRONA DENTAL SYSTEMS GMBH',
      },
      {
        id: 'SOLMETEX (STERISIL)',
      },
      {
        id: 'SPSMEDICAL SUPPLY CORP. A DIV. OF CROSSTEX INTL.',
      },
      {
        id: 'STAR DENTAL',
      },
      {
        id: 'STRYKER CORPORATION',
      },
      {
        id: 'STRYKER INSTRUMENTS',
      },
      {
        id: 'STRYKER INSTRUMENTS, INSTRUMENTS DIV.',
      },
      {
        id: 'STRYKER INSTRUMENTS-A DIVISION OF STRYKER CORP',
      },
      {
        id: 'STRYKER INSTRUMENTS-KALAMAZOO',
      },
      {
        id: 'STRYKER MEDICAL-KALAMAZOO',
      },
      {
        id: 'TAKARA BELMONT CORPORATION, OSAKA FACTORY',
      },
      {
        id: 'TULSA DENTAL PRODUCTS LLC',
      },
      {
        id: 'W AND H DENTALWERK BUERMOOS GMBH',
      },
      {
        id: 'W&H',
      },
      {
        id: 'W&H DENTALWERK',
      },
      {
        id: 'W&H IMPEX',
      },
      {
        id: 'ZILA NOW OWNED BY DENMAT',
      },
    ],
    manufacturers: [
      {
        id: 'ARTHROCARE CORPORATION',
      },
      {
        id: 'SMITH & NEPHEW, INC.',
      },
    ],
  },
};
const loginEndpoint = {
	token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOiJVfmpZUTdQNG5GcCIsIlVzZXJIb21lIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgwLyMiLCJVc2VyRW1haWwiOiJzb3VtaWxyb3lAcG0ubWUiLCJWZXJzaW9uIjoidjEuWC5ZIiwiU3RhbmRhcmRDbGFpbXMiOnsiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgwLyMiLCJleHAiOjE3MzM5MDQzMTIsImlhdCI6MTczMzI5OTUxMiwiaXNzIjoiRW1wb3dlciBSZWcgSW5jLiJ9fQ.9BAB85djnfkGOcqLriNmsilrAtRYqmJhoQDi5c3HJZI",
   user: {
    id: 'U~o2t85denM',
    email: 'soumilroy@pm.me',
    display_name: 'Soumil',
    tenant_id: 'id1',
    status: 'active',
  },
  tenant: {
    id: 'id1',
    name: 'ID1',
    status: 'active',
    metadata: {
      onboarding_status: '',
      default_procodes: null,
      default_competitors: [
        {
          id: 'A-DEC, INC.',
        },
        {
          id: 'ADS OR AJAX DENTAL/GUANGZHOU AJAX MEDICAL EQUIPMENT CO\nLTD',
        },
        {
          id: 'AIR TECHNIQUES, INC.',
        },
        {
          id: 'ASI MEDICAL, INC.',
        },
        {
          id: 'ATR',
        },
        {
          id: 'BELL INTERNATIONAL',
        },
        {
          id: 'BRASSLER',
        },
        {
          id: 'Comperessor tech',
        },
        {
          id: 'CROSSTEX INTERNATIONAL INC.',
        },
        {
          id: 'DCI EQUIPMENT',
        },
        {
          id: 'EIE/ANALYTIC DIVISION OF ORMCO CORP.',
        },
        {
          id: 'ENGLE DENTAL SYSTEMS',
        },
        {
          id: 'FLIGHT DENTAL SYSTEMS, HR DENTAL PRODUCTS, INC.',
        },
        {
          id: 'HENRY SCHEIN',
        },
        {
          id: 'KAVO DENTAL',
        },
        {
          id: 'KAVO DENTAL TECHNOLOGIES LLC',
        },
        {
          id: 'KAVO DNETAL GMBH',
        },
        {
          id: 'MAILLEFER INSTRUMENTS HOLDING SARL',
        },
        {
          id: 'MIDMARK CORPORTATION',
        },
        {
          id: 'MIDWEST DENTAL PRODUCTS',
        },
        {
          id: 'NAKANISHI INC.',
        },
        {
          id: 'NSK AMERICA CORP.',
        },
        {
          id: 'NSK TOCHIGI-KEN',
        },
        {
          id: 'PATTERSON DENTAL SUPPLY, INC.',
        },
        {
          id: 'PELTON & CRANE',
        },
        {
          id: 'SAGE PRODUCTS, INC.',
        },
        {
          id: 'SIRONA DENTAL SYSTEMS GMBH',
        },
        {
          id: 'SOLMETEX (STERISIL)',
        },
        {
          id: 'SPSMEDICAL SUPPLY CORP. A DIV. OF CROSSTEX INTL.',
        },
        {
          id: 'STAR DENTAL',
        },
        {
          id: 'STRYKER CORPORATION',
        },
        {
          id: 'STRYKER INSTRUMENTS',
        },
        {
          id: 'STRYKER INSTRUMENTS, INSTRUMENTS DIV.',
        },
        {
          id: 'STRYKER INSTRUMENTS-A DIVISION OF STRYKER CORP',
        },
        {
          id: 'STRYKER INSTRUMENTS-KALAMAZOO',
        },
        {
          id: 'STRYKER MEDICAL-KALAMAZOO',
        },
        {
          id: 'TAKARA BELMONT CORPORATION, OSAKA FACTORY',
        },
        {
          id: 'TULSA DENTAL PRODUCTS LLC',
        },
        {
          id: 'W AND H DENTALWERK BUERMOOS GMBH',
        },
        {
          id: 'W&H',
        },
        {
          id: 'W&H DENTALWERK',
        },
        {
          id: 'W&H IMPEX',
        },
        {
          id: 'ZILA NOW OWNED BY DENMAT',
        },
      ],
      manufacturers: [
        {
          id: 'ARTHROCARE CORPORATION',
        },
        {
          id: 'SMITH & NEPHEW, INC.',
        },
      ],
    },
  },
};
